import React from 'react';
import BaseComponent from "../BaseComponent";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import {Chronos} from "../../entity/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

import "./TemplateEditorComponent.css";

export default class TemplateEditorLanding extends BaseComponent {

    TEMPLATE_ID_STORAGE_KEY = "debug_templateEditorTemplateId";

    constructor(props, context) {
        super(props, context);

        let templateId = sessionStorage.getItem(this.TEMPLATE_ID_STORAGE_KEY);

        this.initState({
            segments : [],
            templateId,
            currentPage : 1
        });
    }

    componentDidMount() {
        if (this.state.templateId != null) {
            this.loadSegmentDataFromNetwork();
        }
    }

    moveToEditorScreen = (segmentId) => {
        let destination = "debug_template_editor/" + this.state.templateId + "/";

        if (segmentId !== undefined) {
            destination += segmentId;
        }

        this.props.history.push(destination);
    }

    onTemplateLoadClicked = () => {
        if (this.state.templateId != null && this.state.templateId !== "") {
            this.loadSegmentDataFromNetwork(this.state.templateId);
        }
    }

    changeTablePage = (page) => {
        this.loadSegmentDataFromNetwork(undefined, page);
    }

    loadSegmentDataFromNetwork = (templateId, page) => {
        if (templateId === undefined) {
            templateId = this.state.templateId;
        }

        if (page === undefined) {
            page = this.state.currentPage;
        }

        let formData = new FormData();
        formData.append("id", templateId);
        formData.append("page", page);

        Axios.post(ENDPOINTS.debug.getSegments, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    sessionStorage.setItem(this.TEMPLATE_ID_STORAGE_KEY, templateId);

                    this.setState({
                        segments : resp.data.data,
                        totalRows : resp.data.count
                    });
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    render() {
        return (
            <div className={"template-editor"}>
                <div className={"editor-topbar"}>
                    <label>Template ID</label>
                    <input type={"number"} className={"form-control template-id"} name={"templateId"} value={this.state.templateId} onChange={this.handleChange} />
                    <span className={"btn btn-primary"} onClick={this.onTemplateLoadClicked}>Load</span>
                </div>

                <div className={"editor-container"}>
                    <div className={"text-right"}>
                        <button className={"btn btn-success"} onClick={() => this.moveToEditorScreen()}>New Segment</button>
                    </div>

                    <TableComponent
                        className={"common-table alternating clickable"}
                        emptyMessage={"Nothing but dust"}
                        data={this.state.segments}
                        onRowClick={(row) => { this.moveToEditorScreen(row.id);}}>

                        <Column name={"segmentNumber"} title={"Number"} />
                        <Column name={"segmentName"} title={"Name"} />
                        <Column name={"primaryColour"} className={"text-center"} title={"Primary Colour"} />
                        <Column name={"cellColour"} className={"text-center"} title={"Cell Colour"} />
                        <Column name={"itemCount"} className={"text-center"} title={"# Questions"} />
                        <Column name={"id"} className={"text-center"} title={"Actions"} render={(data, row) => {
                            let buttons = [];
                            buttons.push(<span className={"btn btn-primary"}>Edit</span>);

                            return buttons;
                        }} />
                    </TableComponent>

                    <div className={"paginator-container"}>
                        <div className={"spacer"} />
                        <div className={"paginator-area"}>
                            <TablePaginator
                                totalCount={this.state.totalRows}
                                pageSize={20}
                                page={this.state.currentPage}
                                onClick={this.changeTablePage} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}