import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const API = (function(axios) {
    return {
        setAuthenticationToken : (token) => {
            if (token !== undefined && token !== null) {
                axios.defaults.headers.common["authtoken"] = token;
            } else if (token === null) {
                delete axios.defaults.headers.common["authtoken"];
            }
        },
        onAuthFailed : (callback) => {
            if (callback instanceof Function) {
                axios.defaults.validateStatus = (status) => {
                    if (status === 401 || status === 403) {
                        callback();
                    } else {
                        return status;
                    }
                }
            }
        },
        parse(resp) {
            var out = {
                success : false,
                data : null,
                error : "An unknown error has occurred"
            };

            if (resp != null) {
                out.success = resp.data.success;
                out.error = resp.data.error;
                out.data = resp.data.data;
            }

            return out;
        },
        getAPIUrl : (path, includeAuthToken) => {
            let out = process.env.REACT_APP_BASE_URL + path;
            if (includeAuthToken) {
                out = API.appendAuthTokenToUrl(out);
            }
            return out;
        },
        appendAuthTokenToUrl : (url) => {
            let suffix = {
                at : escape(axios.defaults.headers.common["authtoken"])
            };
            return url + API.createQueryString(suffix);
        },
        createQueryString : (data) => {
            let out = "";
            if (data !== undefined && data != null) {
                for (const [key, value] of Object.entries(data)) {
                    if (out === "") {
                        out = "?"
                    } else {
                        out += "&"
                    }

                    out += key + "=" + escape(value);
                }
            }
            return out;
        },
        caseRecordFileTypes : {
            NELA : 1,
            CRME : 2,
            THDB : 3
        },
        userRoles : {
            SUPER_ADMIN : 1,
            ADMIN : 2,
            USER : 3,
            SUPER_USER : 4
        },
        visibility : {
            FULL : 1,
            LIMITED : 2
        },
        getVisibilityForUserRole : (role) => {
            role = parseInt(role);
            switch (role) {
                case API.userRoles.SUPER_ADMIN:
                case API.userRoles.ADMIN:
                    return API.visibility.FULL;
            }
            return API.visibility.LIMITED;
        }
    }

})(axios);
 
export const ENDPOINTS = {
    login : {
        submit : "auth/login",
        requestPasswordReset : "auth/requestPasswordReset",
        validatePasswordResetToken : "auth/validatePasswordResetToken",
        submitPasswordReset : "auth/submitPasswordReset"
    },
    user : {
        submitUser: "user/submitUser",
        getUser: "user/fetchUser",
        getAllUsers : "user/fetchAllUsers",
        sendPasswordReset : "user/sendPasswordReset",
        sendRegistrationReminder : "user/sendRegistrationReminder",
        deleteUser : "user/deleteUser",
        getUsers : "user/getUsers",
        getCaseRecords : "user/getCaseRecords",
        updateUserPassword : "user/updateUserPassword",
        getUserRoles : "user/getUserRoles"
    },
    caseRecord : {
        uploadCaseRecordFile : "caseRecord/uploadCaseRecordFile",
        submitCaseRecord : "caseRecord/submitCaseRecord",
        getCaseRecord : "caseRecord/getCaseRecord",
        getCaseRecords : "caseRecord/getCaseRecords",
        submitPatientData : "caseRecord/submitPatientData",
        getSectionResultOutcomes : "caseRecord/getSectionResultOutcomes",
        addUserToCaseRecord : "caseRecord/addUserToCaseRecord",
        removeUserFromCaseRecord : "caseRecord/removeUserFromCaseRecord",
        getMissingClinicians : "caseRecord/getMissingClinicians",
        removeMissingClinician : "caseRecord/removeMissingClinician",
        getCaseRecordStages : "caseRecord/getCaseRecordStages",
        calculateProcessSectionItem : "caseRecord/calculateProcessSectionItem",
        getCaseRecordFileHistory : "caseRecord/getCaseRecordFileHistory",
        generateCaseRecordExport : "caseRecord/generateCaseRecordExport",
        getClinicalRoles : "caseRecord/getClinicalRoles",
        notifyUsersOfCaseRecordUpdate : "caseRecord/notifyUsersOfCaseRecordUpdate",
        submitCaseRecordAutoAssign : "caseRecord/submitCaseRecordAutoAssign",
        removeCaseRecordAutoAssign : "caseRecord/removeCaseRecordAutoAssign",
        getCaseRecordAutoAssignRecords : "caseRecord/getCaseRecordAutoAssignRecords",
        deleteCaseRecord : "caseRecord/deleteCaseRecord"
    },
    location : {
        getLocation : "location/getLocation",
        getLocations : "location/getLocations",
        getLocationUsers : "location/getLocationUsers",
        submitLocation : "location/submitLocation",
        addUserToLocation : "location/addUserToLocation",
        removeUserFromLocation : "location/removeUserFromLocation"
    },
    debug : {
        getTemplate : "caseRecord/debug_getCaseRecordTemplate",
        getSegments : "caseRecord/debug_getCaseRecordTemplateSegments",
        submitTemplate : "caseRecord/debug_submitCaseRecordTemplate"
    },
    report : {
        generateSummaryReport : "report/generateSummaryReport",
        getMortalityReportTableData : "report/getMortalityReportTableData",
        generateMortalityReportExport : "report/generateMortalityReportExport",
        getBTPSummaryData : "report/getBTPSummaryData",
        getBPTSummaryTableData : "report/getBPTSummaryTableData",
        generateBPTSummaryExport : "report/generateBPTSummaryExport",
        getReflectionTableData : "report/getReflectionTableData",
        getFeedbackFilterConfig : "report/getFeedbackFilterConfig",
        getOpenReflection : "report/getOpenReflection",
        generateOpenReflectionExport : "report/generateOpenReflectionExport"
    },
    patient : {
        getPatientTableData : "patient/getPatientTableData"
    }
}