import React from "react";
import BaseComponent from "../BaseComponent";

import "./PasswordResetComponent.css";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import AlertModal from "../common/AlertModal";
import {Link} from "react-router-dom";

export default class PasswordResetComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let token = null;
        if (this.props.match.params !== undefined) {
            if (this.props.match.params.token !== undefined) {
                token = this.props.match.params.token;
            }
        }

        this.initState({
            validationNetworkInFlight : false,
            submissionNetworkInFlight : false,
            submissionSuccess : false,
            tokenValid : false,
            resetToken : token,
            password : null,
            passwordRetype : null,
            modalOpen : false,
            modalTitle : null,
            modalContent : null,
            modalButtons : []
        });
    }

    componentDidMount() {
        super.componentDidMount();

        this.validatePasswordResetToken();
    }

    validatePasswordResetToken = () => {
        if (this.state.validationNetworkInFlight) return;
        this.setState({ validationNetworkInFlight : true });

        let formData = new FormData();
        formData.append("token", this.state.resetToken);

        Axios.post(ENDPOINTS.login.validatePasswordResetToken, formData)
            .then((r) => {
                let resp = API.parse(r);
                this.setState({
                    validationNetworkInFlight : false,
                    tokenValid : resp.success
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    validationNetworkInFlight : false,
                    tokenValid : false
                });
            });
    }

    submitPasswordReset = () => {
        if (this.state.submissionNetworkInFlight) return;

        if (this.state.password !== "" && this.state.passwordRetype) {
            if (this.state.password !== this.state.passwordRetype) {
                this.showError("Password and Password Confirmation do not match.");
                return;
            }
        } else {
            this.showError("Please provide a Password and Password Confirmation");
            return;
        }

        this.setState({
            submissionNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("token", this.state.resetToken);
        formData.append("password", this.state.password);

        Axios.post(ENDPOINTS.login.submitPasswordReset, formData)
            .then((r) => {
                let resp = API.parse(r);

                let state = {
                    submissionNetworkInFlight : false
                };

                if (resp.success) {
                    state.submissionSuccess = true;
                } else {
                    this.showError(resp.error.desc);
                }

                this.setState(state);
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later.");
            })
    }

    showError = (message) => {
        this.showModal("Error", message, [
            {
                label : "OK",
                click : () => {
                    this.hideModal();
                }
            }
        ]);
    }

    showModal = (title, message, buttons) => {
        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalContent : message,
            modalButtons : buttons
        });
    }

    hideModal = () => {
        this.setState({
            modalOpen : false
        });
    }

    render() {
        let content = [];

        if (this.state.validationNetworkInFlight) {
            content = (
                <div className={"text-center"}>
                    <h2>Please wait...</h2>
                    <p>Checking your Password Reset session.</p>
                    <p>This may take a few moments.</p>
                </div>
            );
        } else if (this.state.submissionSuccess) {
            content = (
                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        <h2>Success</h2>
                        <p>Your password has been set successfully.</p>
                        <p className={"mt-2"}><strong>App users:</strong> Please return to the QI Notify-EmLap App and login using the email address your password reset email was sent to and the password you just created.</p>
                        <p className={"mt-2"}><strong>Admin users only (accessing the web portal):</strong> Please return to the QI Notify-EmLap web-portal login page and login using the email address your password reset email was sent to and the password you just created.</p>
                        <p>Any difficulties with accessing your account please contact your local QI Notify-EmLap Admin or Clinical lead.</p>
                    </div>
                </div>
            )
        } else if (this.state.tokenValid) {
            let submitButton = (<button className={"btn btn-primary"} onClick={() => this.submitPasswordReset()}>Submit</button>);
            if (this.state.submissionNetworkInFlight) {
                submitButton = (<button className={"btn btn-primary disabled"}>Please wait</button>)
            }

            content = (
                <div>
                    <div className={"row"}>
                        <div className={"col-12 text-center"}>
                            <h1>Password Reset</h1>
                            <div>Please provide a new password below.</div>
                        </div>
                    </div>

                    <div className={"row field-row"}>
                        <div className={"col-12"}>
                            <label>New Password</label>
                            <input type={"password"} className={"form-control"} name={"password"} value={this.state.password} onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12 field-row"}>
                            <label>Confirm New Password</label>
                            <input type={"password"} className={"form-control"} name={"passwordRetype"} value={this.state.passwordRetype} onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className={"row field-row"}>
                        <div className={"col-12 submit-area"}>
                            {submitButton}
                        </div>
                    </div>
                </div>
            );
        } else {
            content = (
                <div className={"text-center"}>
                    <h2>Oops!</h2>
                    <p>This Password Reset session has expired.</p>
                    <p>For your safety, password reset sessions are time-limited.</p>
                    <p>Please request a new Password Reset from the login screen and try again.</p>
                </div>
            );
        }

        return (
            <div className={"password-reset-screen"}>
                <div className={"row"}>
                    <div className={"hidden-xs col-md-2 col-lg-4"} />
                    <div className={"col-12 col-md-8 col-lg-4"}>
                        <div className={"field-box"}>
                            {content}
                        </div>
                    </div>
                </div>

                <AlertModal
                    open={this.state.modalOpen}
                    title={this.state.modalTitle}
                    content={this.state.modalContent}
                    buttons={this.state.modalButtons} />
            </div>
        )
    }
}