import React from "react";
import BaseComponent from "./BaseComponent";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import {API} from "../network/API";
import LoginComponent from "./login/LoginComponent";
import TemplateEditorComponent from "./debug/TemplateEditorComponent";
import SideNavigationComponent from "./common/SideNavigationComponent";
import TopBarComponent from "./common/TopBarComponent";
import CaseRecordEditorLandingComponent from "./caseRecord/editor/CaseRecordEditorLandingComponent";
import CaseRecordEditorFormComponent from "./caseRecord/editor/CaseRecordEditorFormComponent";
import CaseRecordDatabaseComponent from "./caseRecord/CaseRecordDatabaseComponent";
import UserAccountsComponent from "./users/UserAccountsComponent";
import ViewUserComponent from "./users/ViewUserComponent";
import history from 'history/browser';

import logo from "../assets/qinotify/img/logo.png";
import NewUserComponent from "./users/NewUserComponent";
import PasswordResetComponent from "./login/PasswordResetComponent";
import ForgottenPasswordComponent from "./login/ForgottenPasswordComponent";
import LocationLandingComponent from "./location/LocationLandingComponent";
import LocationDetailComponent from "./location/LocationDetailComponent";
import TemplateEditorLanding from "./debug/TemplateEditorLanding";
import ReportsLandingComponent from "./reports/ReportsLandingComponent";
import UserDetailComponent from "./users/UserDetailComponent";


class MainComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            user : null,
            sessionToken : null,
            primaryContentMinHeight : 0,
            applyWidthFix : false
        });

        this.onUserLogin = this.onUserLogin.bind(this);
        this.onUserLogout = this.onUserLogout.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.setTitle = this.setTitle.bind(this);

        if (!window.hasOwnProperty("widthFixCallback")) {
            setTimeout(() => {
                window.widthFixCallback = this.windowFixCallback;
            }, 10);
        }
    }

    componentDidMount() {
        let user = sessionStorage.getItem("user");
        let sessionToken = sessionStorage.getItem("sessionToken");

        if (user !== null && sessionToken !== null) {
            user = JSON.parse(user);
            this.onUserLogin(user, sessionToken);
        }

        API.onAuthFailed(() => {
            console.log("AUTH FAILURE!");
            this.onUserLogout();

            setTimeout(() => {
                window.location.href = "/"; // Force page reload
            }, 50);
        })

        window.addEventListener('resize', this.updateWindowDimensions);
        this.updateWindowDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({
            primaryContentMinHeight : window.innerHeight - 50
        });
    }

    /**
     * Callback for Window Fix
     *
     * Sometimes the calculation of available screen space fails for whatever reason
     * and causes the timeline to overflow on the Case Record Form screen. There was
     * a fix, but it had a knock-on effect on other screens. So instead we now just
     * request that the fix be applied when its needed rather than just apply it broadly.
     *
     * @param applyFix
     */
    windowFixCallback = (applyFix) => {
        this.setState({
            applyWidthFix : applyFix
        });
    }

    /**
     * Login callback handler
     * @param user              User object returned from API
     * @param sessionToken      Session Token returned from API
     */
    onUserLogin(user, sessionToken) {
        sessionStorage.setItem("user", JSON.stringify(user));

        if (sessionToken !== undefined) {
            sessionStorage.setItem("sessionToken", sessionToken);
            API.setAuthenticationToken(sessionToken);
        }

        this.setState({
            user : user,
            topBarTitle : null,
            topBarBackButtonVisible : false,
            topBarBackButtonTo : null
        });
    }

    setTitle(title, options) {
        let backButtonVisible = false;
        let backButtonTo = null;
        if (options !== undefined) {
            if (options.hasOwnProperty("backButton")) {
                let backButton = options.backButton;
                if (backButton.hasOwnProperty("visible")) {
                    backButtonVisible = backButton.visible;
                }

                if (backButton.hasOwnProperty("to")) {
                    backButtonTo = backButton.to;
                }
            }
        }

        this.setState({
            topBarTitle : title,
            topBarBackButtonVisible : backButtonVisible,
            topBarBackButtonTo : backButtonTo
        });
    }

    /**
     * General logout handler. Clears up user data and state.
     */
    onUserLogout() {
        sessionStorage.clear();

        API.setAuthenticationToken(null);
        this.setState({ user : null });

        window.location.href = "/"; // Yes, force a refresh
    }

    render() {
        if (this.state == null || this.state.user == null) {
            return (
                <BrowserRouter>
                    <Switch>
                        <Route
                            path={"/"} exact
                            render={(props) =>
                                <LoginComponent
                                    onLoginCallback={this.onUserLogin} />
                            } />

                        <Route
                            path={"/forgot"} exact
                            render={(props) =>
                                <ForgottenPasswordComponent {...props} />
                            } />

                        <Route
                            path={"/forgotten/:token"}
                            render={(props) =>
                                <PasswordResetComponent {...props} />
                            } />
                    </Switch>
                </BrowserRouter>
            )
        } else {
            let widthFixClass = "";
            console.log("Main Component Render: applyWidthFix: " + (this.state.applyWidthFix ? "TRUE" : "FALSE"));
            if (this.state.applyWidthFix) {
                console.log("Applying style...");
                widthFixClass = " width-fix";
            }

            return(
                <BrowserRouter>

                    <TopBarComponent
                        title={this.state.topBarTitle}
                        backButtonVisible={this.state.topBarBackButtonVisible}
                        backButtonTo={this.state.topBarBackButtonTo} />

                    <div className={"primary-content-container" + widthFixClass} style={{minHeight : this.state.primaryContentMinHeight}}>
                        <SideNavigationComponent mode={SideNavigationComponent.MODE_FULL} />
                        <div className={"container-fluid"}>
                            <Switch>
                                <Route
                                    path={"/"} exact
                                    render={(props) =>
                                        <CaseRecordDatabaseComponent title={this.setTitle} {...props} />
                                    } />

                                <Route
                                    path={"/create/:id?"} exact
                                    render={(props) =>
                                        <CaseRecordEditorLandingComponent title={this.setTitle} {...props} />
                                    } />

                                <Route
                                    path={"/create/segment/:index/"}
                                    render={(props) =>
                                        <CaseRecordEditorFormComponent title={this.setTitle} {...props} />
                                    } />

                                <Route
                                    path={"/debug_template_editor/"} exact
                                    render={(props) =>
                                        <TemplateEditorLanding title={this.setTitle} {...props} />
                                    } />

                                <Route
                                    path={"/debug_template_editor/:templateId/:segmentId?"}
                                    render={(props) =>
                                        <TemplateEditorComponent title={this.setTitle} {...props} />
                                    } />

                                <Route
                                    path={"/users"} exact
                                    render={(props) =>
                                        <UserAccountsComponent title={this.setTitle} {...props} />
                                    } />
                                <Route
                                    path={"/users/view/:id?"}
                                    render={(props) =>
                                        <ViewUserComponent title={this.setTitle} {...props} />
                                    } />
                                <Route
                                    path={"/users/new"} exact
                                    render={(props) =>
                                        <NewUserComponent title={this.setTitle} {...props} />
                                    } />

                                <Route
                                    path={"/locations"} exact
                                    render={(props) => 
                                        <LocationLandingComponent title={this.setTitle} {...props} />
                                    } />

                                <Route
                                    path={"/locations/view/:id"} exact
                                    render={(props) =>
                                        <LocationDetailComponent title={this.setTitle} {...props} />
                                    } />

                                <Route
                                    path={"/reports/:section?"}
                                    render={(props) =>
                                        <ReportsLandingComponent title={this.setTitle} {...props} />
                                } />

                                <Route
                                    path={"/user-details"} exact
                                    render={(props) =>
                                        <UserDetailComponent title={this.setTitle} onLogout={this.onUserLogout} {...props} />
                                    } />

                            </Switch>
                        </div>
                    </div>
                </BrowserRouter>
            )
        }
    }

}

export default MainComponent;