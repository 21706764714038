import React from "react";
import BaseComponent from "../BaseComponent";

import './caseRecordDatabaseComponent.css';
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import {Chronos} from "../../entity/Chronos";

import DatePicker from "react-datepicker";

import downloadIcon from "../../assets/qinotify/img/icon_download_inverse.svg";
import viewIcon from "../../assets/qinotify/img/icon_view.svg";

import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../common/LoadingSpinner";

class CaseRecordDatabaseComponent extends BaseComponent {

    keywordFilterTimeout = undefined;

    constructor(props, context) {
        super(props, context);

        let user = this.getUser();

        this.initState({
            user,
            caseRecords : [],
            networkInFlight : false,
            filterKeyword : "",
            currentPage : 1,
            totalRows : 0,
            locations : [],
            locationId : -1,
            caseRecordDownloadingId : -1
        });

        console.log("HISTORY ON CONSTRUCT");
        console.log(this.props.history);

        this.getCaseRecordsFromNetwork = this.getCaseRecordsFromNetwork.bind(this);
    }

    componentDidMount() {
        this.getCaseRecordsFromNetwork();
        this.fetchLocationsFromNetwork();

        this.props.title("Case Record Database");
    }

    componentWillUnmount() {
        clearTimeout(this.keywordFilterTimeout);
    }

    handleDateChange = (name, date) => {
        let outDate = date.getTime() / 1000;

        let state = {};
        state[name] = outDate;

        this.setState(state);

        this.handleFilterChange();
    }

    handleFilterChange = (e) => {
        if (e !== undefined) {
            this.handleChange(e);
        }

        this.setState({
            currentPage : 1
        });

        setTimeout(() => {
            this.getCaseRecordsFromNetwork(1);
        }, 100);
    }

    clearFilterDates = () => {
        this.setState({
            startDate : null,
            endDate : null
        });

        this.handleFilterChange();
    }

    getCaseRecordsFromNetwork(page) {
        if (this.state.networkInFlight) return;
        this.setState({
            networkInFlight : true
        });

        if (page === undefined) {
            page = this.state.currentPage;
        }

        let formData = new FormData();
        formData.append("page", page);

        if (this.state.startDate != null) {
            let chronosStart = Chronos.withTimestampSeconds(this.state.startDate);
            if (chronosStart.isValid()) {
                formData.append("startDate", this.state.startDate);
            }
        }

        if (this.state.endDate != null) {
            let chronosEnd = Chronos.withTimestampSeconds(this.state.endDate);
            if (chronosEnd.isValid()) {
                formData.append("endDate", this.state.endDate);
            }
        }

        if (this.state.locationId > 0) {
            formData.append("locationId", this.state.locationId);
        }

        if (this.state.filterKeyword !== "") {
            formData.append("keyword", this.state.filterKeyword);
        }

        Axios.post(ENDPOINTS.caseRecord.getCaseRecords, formData)
            .then((r) => {
                let totalRows = 0;
                let caseRecords = null;

                let resp = API.parse(r);
                if (resp.success) {
                    totalRows = resp.data.total;
                    caseRecords = resp.data.caseRecords
                } else {
                    // TODO Show error
                    console.log(resp.error);
                }

                this.setState({
                    networkInFlight: false,
                    totalRows : totalRows,
                    caseRecords : caseRecords
                });
            })
            .catch((e) => {
                this.setState({
                    networkInFlight: false
                });

                console.log(e);
            });
    }

    getCaseRecordFromNetwork(id) {
        if (this.state.networkInFlight) return;
        this.setState({
            networkInFlight : true
        });

        let formData = new FormData();
        formData.append("caseRecordId", id);
        formData.append("isDashboard", 1);

        Axios.post(ENDPOINTS.caseRecord.getCaseRecord, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    // TODO Should we do something here?
                } else {
                    console.log(resp.error);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    fetchLocationsFromNetwork = () => {
        let formData = new FormData();

        Axios.post(ENDPOINTS.location.getLocations, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.processLocations(resp.data.locations);
                } else {
                    console.log(resp.error.desc);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    processLocations = (locations) => {
        locations.unshift({
            id : -1,
            name : "Any Location"
        });

        this.setState({
            locations : locations
        });
    }

    moveToEditor = (caseRecord) => {

        let url = "/create";
        if (caseRecord !== undefined) {
            url += "/" + caseRecord.id;
        }

        this.props.history.push(url);
    }

    changeTablePage = (index) => {
        this.setState({
            currentPage : index
        });

        this.getCaseRecordsFromNetwork(index);
    }

    caseRecordRowWasClicked = (row) => {
        console.log("ROW CLICK");
    }

    filterInputDidChange = (e) => {
        console.log("SET TIMEOUT");
        clearTimeout(this.keywordFilterTimeout);
        this.keywordFilterTimeout = setTimeout(() => {
            console.log("TIMEOUT!");
            this.getCaseRecordsFromNetwork();
        }, 600);

        this.handleChange(e);
    }

    exportCaseRecordOverNetwork = (caseRecord) => {
        if (this.state.exportNetworkInFlight) return;

        this.setState({
            caseRecordDownloadingId : caseRecord.id,
            exportNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("caseRecordId", caseRecord.id);

        Axios.post(ENDPOINTS.caseRecord.generateCaseRecordExport, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let url = API.appendAuthTokenToUrl(resp.data.url);
                    console.log(url);
                    window.open(url);
                } else {
                    console.log(resp.error);
                }

                this.setState({
                    caseRecordDownloadingId : -1,
                    exportNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    caseRecordDownloadingId : -1,
                    exportNetworkInFlight : false
                });
            });
    }

    render() {
        let creationButton = [];
        if (this.state.user.visibility === API.visibility.FULL) {
            creationButton = (
                <div className={"action"}>
                    <span className={"btn btn-primary"} onClick={() => this.moveToEditor()}>Add new case record</span>
                </div>
            );
        }

        return (
            <div className={"case-record-database"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"actions"}>

                            {creationButton}

                            <div className={"spacer"} />
                            <div className={"action"}>
                                <div className={"input-group"}>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Search</span>
                                    </div>
                                    <input type={"text"} className={"form-control"} name={"filterKeyword"} onChange={this.filterInputDidChange} placeholder={"Search"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row filter-area"}>
                    <div className={"col-12"}>
                        <div className={"card card-padded"}>
                            <div className={"row field-margin"}>
                                <div className={"col-12"}>
                                    <label>Show Case Records for Location:</label>
                                    <select className={"form-control"} name={"locationId"} onChange={this.handleFilterChange}>
                                        {
                                            this.state.locations.map((location) => (
                                                <option value={location.id} selected={this.state.locationId === location.id}>{location.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className={"row field-margin"}>
                                <div className={"col-6 col-md-4"}>
                                    <label>Start Date</label>
                                    <DatePicker
                                        selected={(this.state.startDate != null) ? Chronos.withTimestampSeconds(this.state.startDate).getDate() : null}
                                        onChange={(date) => this.handleDateChange("startDate", date)}
                                        className={"form-control"}
                                        dateFormat={"dd/MM/yyyy"} />
                                </div>

                                <div className={"col-6 col-md-4"}>
                                    <label>End Date</label>
                                    <DatePicker
                                        selected={(this.state.endDate != null) ? Chronos.withTimestampSeconds(this.state.endDate).getDate() : null}
                                        onChange={(date) => this.handleDateChange("endDate", date)}
                                        className={"form-control"}
                                        dateFormat={"dd/MM/yyyy"} />
                                </div>

                                <div className={"col-12 col-md-4"}>
                                    <label>&nbsp;</label>
                                    <button className={"btn btn-primary"} onClick={this.clearFilterDates}>Clear Dates</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row table-area"}>
                    <div className={"col-12"}>
                        <TableComponent
                            className={"common-table alternating clickable"}
                            emptyMessage={"There are no Case Records to display"}
                            data={this.state.caseRecords}
                            onRowClick={(row) => { this.caseRecordRowWasClicked(row, true);}}>

                            <Column name={"recordId"} title={"Case Record Number"} />
                            <Column name={"givenName"} title={"Patient Name"} render={(data, row) => {
                                return row.givenName + " " + row.familyName;
                            }} />
                            <Column name={"dateCreated"} className={"text-center"} title={"Date Record Added"} render={(data, row) => {
                                if (row != null) {
                                    return Chronos.withTimestampSeconds(data).format("dd/MM/yyyy");
                                }
                                return "";
                            }} />
                            <Column name={"stageName"} className={"text-center"} title={"Record Stage"} />
                            <Column name={"id"} className={"text-center"} title={"Actions"} render={(data, row) => {
                                let buttons = [];
                                buttons.push(<span className={"btn btn-primary"} onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.moveToEditor(row) }}><span className={"btn-icon"} style={{backgroundImage : "url(" + viewIcon + ")"}} /></span>);
                                buttons.push(<span>&nbsp;</span>);
                                if (this.state.caseRecordDownloadingId === row.id) {
                                    buttons.push(<span className={"btn btn-danger disabled"}><LoadingSpinner small={true} inverse={true} /></span> )
                                } else {
                                    buttons.push(<span className={"btn btn-danger"} onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.exportCaseRecordOverNetwork(row);}}><span className={"btn-icon"} style={{backgroundImage : "url(" + downloadIcon + ")"}} /></span>);
                                }

                                return buttons;
                            }} />

                        </TableComponent>

                        <div className={"paginator-container"}>
                            <div className={"spacer"} />
                            <div className={"paginator-area"}>
                                <TablePaginator
                                    totalCount={this.state.totalRows}
                                    pageSize={10}
                                    page={this.state.currentPage}
                                    onClick={this.changeTablePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default CaseRecordDatabaseComponent;