import React from "react";
import logo from "../../assets/qinotify/img/logo.png";
import {Link, NavLink} from "react-router-dom";

import accountIcon from "../../assets/qinotify/img/icon_account.svg";

class TopBarComponent extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    render() {
        let titleContainerElements = [];
        if (this.props.hasOwnProperty("backButtonVisible")) {
            if (this.props.backButtonVisible) {
                titleContainerElements.push(
                    <Link to={this.props.backButtonVisible} className={"nav-back-button"} />
                )
            }
        }

        if (this.props.hasOwnProperty("title")) {
            titleContainerElements.push(
                <div className={"title-text"}>{this.props.title}</div>
            )
        }

        return (
            <div className={"header-topbar"}>
                <div className={"logo"} style={{ backgroundImage : "url(" + logo + ")" }} />
                <div className={"title-container"}>
                    {titleContainerElements}
                </div>
                <div className={"actions"}>
                    <NavLink to={"/user-details"} className={"account-icon"} style={{backgroundImage : "url(" + accountIcon + ")"}} />
                </div>
            </div>
        )
    }

}

export default TopBarComponent;