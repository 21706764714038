import React from "react";
import BaseComponent from "../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import AlertModal from "../common/AlertModal";

import "./LoginComponent.css";
import {Link} from "react-router-dom";

import qilogo from "../../assets/qinotify/img/qinotify_logo.png";
import wmalogo from "../../assets/qinotify/img/wmahsn_logo.png";
import emlaplogo from "../../assets/qinotify/img/emlap_logo.png";
import dudleyNhsLogo from "../../assets/qinotify/img/dudly_logo.jpg";

import {Chronos} from "../../entity/Chronos";

class LoginComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.state = {
            username : null,
            password : null,
            networkInFlight : false,
            modalOpen : false,
            modalTitle : null,
            modalContent : null,
            modalButtons : null,
            showBanner : true
        };

        this.submitLogin = this.submitLogin.bind(this);
        this.showModal = this.showModal.bind(this);
    }

    submitLogin() {
        let validationResult = this.validateCreateFormData(this.state, [
            { key : "username", type : "string", label: "Username", postName : "emailAddress" },
            { key : "password", type : "string", label : "Password", postName : "password" }
        ]);

        if (validationResult) {
            let formData = validationResult.formData;
            formData.append("referer", "dashboard");

            Axios.post(ENDPOINTS.login.submit, formData)
                .then((r) => {
                    let resp = API.parse(r);
                    if (resp.success) {
                        if (resp.data != null) {
                            this.props.onLoginCallback(resp.data.user, resp.data.sessionToken);
                        }
                    } else {
                        this.showModal("Error", resp.error.desc);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            this.showModal("Error", "An unknown error has occurred. Please try again later.");
        }
    }

    showModal(title, message, buttons) {
        if (buttons === undefined) {
            buttons = [{label : "OK", click : () => { this.hideModal(); }}];
        }

        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalContent : message,
            modalButtons : buttons
        });
    }

    hideModal() {
        this.setState({
            modalOpen : false
        });
    }

    onInputKeyUp = (event) => {
        if (event.keyCode === 13) {
            this.submitLogin();
        }
    }

    render() {
        let topBanner = [];
        if (this.state.showBanner) {
            topBanner = (
                <div className={"top-banner"}>
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div>The main Dudley Group QI Notify-EmLap web-page is available <a href={"http://www.dgft.nhs.uk/about-us/quality/qi-notify-emlap/"}>here</a>, and our Privacy Statement is available <a href={"http://www.dgft.nhs.uk/qi-notify-emlap-privacy-statement/"}>here</a>.</div>
                                <div>Please take a moment to read and understand more about the solution and how we use your data.</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <div className={"login-screen"}>
                    {topBanner}

                    <div className={"container"}>
                        <div className={"row justify-content-center"}>
                            <div className={"col-12 col-md-8 col-lg-6"}>
                                <div className={"row piccadilly"}>
                                    <div className={"col-12"}>
                                        <div className={"qi-logo"} style={{backgroundImage : "url(" + qilogo + ")" }} />
                                    </div>
                                    <div className={"col-6"}>
                                        <div className={"small-logo"} style={{backgroundImage : "url(" + wmalogo + ")" }} />
                                    </div>
                                    <div className={"col-6"}>
                                        <div className={"small-logo"} style={{backgroundImage : "url(" + emlaplogo + ")" }} />
                                    </div>
                                </div>

                                <div className={"row mt-4 justify-content-center"}>
                                    <div className={"col-12"}>
                                        <div className={"alert alert-info text-center"}>
                                            <div><strong>This is the Admin web-portal for QI Notify-EmLap, and is only accessible to staff with admin rights.</strong></div>
                                            <div>If you have a user account you must login to QI Notify-EmLap via the App on your phone.</div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"login-box"}>
                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <input type={"text"} className={"form-control"} name={"username"} value={this.state.username} placeholder={"Email Address"} onChange={this.handleChange} onKeyUp={this.onInputKeyUp} />
                                        </div>
                                    </div>

                                    <div className={"row field-row"}>
                                        <div className={"col-12"}>
                                            <input type={"password"} className={"form-control"} name={"password"} value={this.state.password} placeholder={"Password"} onChange={this.handleChange} onKeyUp={this.onInputKeyUp} />
                                        </div>
                                    </div>

                                    <div className={"row field-row"}>
                                        <div className={"col-12 submit-button"}>
                                            <span className={"btn btn-primary"} onClick={this.submitLogin}>Login</span>
                                            <Link to={"/forgot"}>Forgotten Password</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"spacer"} />

                    <div className={"footer"}>
                        <div><span className={"logo"} style={{backgroundImage : "url(" + dudleyNhsLogo +  ")"}} /></div>
                        <div>QI Notify&trade;</div>
                        <div>Copyright &copy; 2020-{Chronos.now().format("yyyy")} The Dudley Group NHS Foundation Trust. All rights reserved.</div>
                    </div>
                </div>

                <AlertModal
                    open={this.state.modalOpen}
                    title={this.state.modalTitle}
                    content={this.state.modalContent}
                    buttons={this.state.modalButtons} />
            </div>
        );
    }
}

export default LoginComponent;