import React from "react";
import {NavLink} from "react-router-dom";
import BaseComponent from "../BaseComponent";
import {API} from "../../network/API";

const MODE_FULL = 1;
const MODE_LIMITED = 2;

class SideNavigationComponent extends BaseComponent {

    static get MODE_FULL() { return MODE_FULL }
    static get MODE_LIMITED() { return MODE_LIMITED }

    navigationItems = []

    constructor(props, context) {
        super(props, context);

        let user = this.getUser();

        let mode = user.visibility === API.visibility.FULL ? MODE_FULL : MODE_LIMITED;

        this.navigationItems = [
            { label : "Case Record Database", to : "/", exact : true },
            { label : "Summary Reports", to : "/reports", exact : false }
        ]

        if (mode === MODE_FULL) {
            this.navigationItems.unshift({ label : "Create a Case Record", to : "/create", exact : false });
            this.navigationItems.push({ label : "User Accounts", to: "/users", exact : false });
            this.navigationItems.push({ label : "Locations", to : "/locations", exact : false });
        }
    }

    render() {
        return(
            <div className={"header-sidenav"}>
                {this.navigationItems.map((item) => (
                    <NavLink
                        to={item.to}
                        exact={item.exact}
                        className={"nav-link"}
                        activeClassName={"active"}>
                        {item.label}
                    </NavLink>
                ))}
            </div>
        )
    }

}

export default SideNavigationComponent;