import React from 'react';
import BaseComponent from '../BaseComponent';

import UserVerificationComponent from './User Verification Component/UserVerificationComponent';
import UserDetailsComponent from './User Details Component/UserDetailsComponent';
import LinkedRecordsComponent from './Linked Record Component/LinkedRecordsComponent';
import AlertModal from "../common/AlertModal";

import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

import './UserAccounts.css';


class ViewUserComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let forgottenPasswordUrl = this.getHashProperty("forgottenPasswordUrl", null);
        
        this.initState({
            user: null,
            userRegistrationIsComplete: true,
            userHasChanges: false,
            userIsReadyForSubmission : false,
            networkInFlight: false,
            forgottenPasswordUrl
        });
    }


    // MARK: Life-cycle

    componentDidMount() {
        this.props.title("User Details");
        let userId = this.fetchUserIdFromURL();

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);

        if (userId) {
            this.fetchUserFromNetwork(userId);
        } else {
            this.moveToCreateNewUser();
        }
    }

    componentWillUnmount() {

    }

    fetchUserIdFromURL() {
        return parseInt(this.props.match.params.id)
    }


    // MARK: Navigation

    moveToUserList() {
        let url = "/users";
        this.props.history.push(url);
    }

    moveToCreateNewUser() {
        let url = "/users/new";
        this.props.history.push(url);
    }


    // MARK: Actions

    userStateDidChange(user) {
        this.setState({
            user: user,
            userHasChanges: true
        });

        this.updateUserSubmissionState();
    }

    updateUserSubmissionState() {
        let user = this.state.user;
        if (this.state.userIsReadyForSubmission) {
            this.setState({
                userIsReadyForSubmission : false
            });
        }

        if (user.givenName && user.familyName && user.clinicalRoleId && user.placeOfWork && user.gmcNumber && user.expectedEndDate && user.emailAddress) {
            this.setState({
                userIsReadyForSubmission : true
            });
        }
    }

    saveChangesTapped() {
        if (!this.state.userHasChanges || !this.state.userIsReadyForSubmission) { return }

        this.showModal(
            "Save Changes",
            "Are you sure you wish to save these changes? The user's previous details will be overwritten and cannot be recovered.",
            [
                {
                    label : "Save",
                    click : () => {
                        this.saveChangesToUser();
                        this.hideModal();
                    }
                }, 
                {
                    label : "Cancel",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]
        );
    }

    sendPasswordResetTapped() {
        this.showModal(
            "Send Password Reset",
            "Are you sure you wish to send the user a password reset email? The user will no longer be able to use their old password.",
            [
                {
                    label : "Reset Password",
                    click : () => {
                        this.sendPasswordResetNetworkRequest();
                        this.hideModal();
                    }
                }, 
                {
                    label : "Cancel",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]
        );
    }

    deleteUserTapped = () => {
        this.showModal(
            "Warning - Delete User",
            "Are you sure you wish to delete this user? This user and ALL of their data will be deleted. This action can NOT be undone.",
            [
                {
                    label : "Permanently Delete",
                    click : () => {
                        this.deleteUser();
                        this.hideModal();
                    }
                }, 
                {
                    label : "Cancel",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]
        );
    }


    // MARK: Alerts

    showModal(title, message, buttons) {
        if (buttons === undefined) {
            buttons = [
                {
                    label : "OK",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]
        }

        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalContent : message,
            modalButtons : buttons
        });
    }

    hideModal() {
        this.setState({
            modalOpen : false
        });
    }


    // MARK: Networking

    fetchUserFromNetwork(userId) {
        if (this.state.networkInFlight) { return }
        this.setState({
            networkInFlight : true
        });

        let formData = new FormData();
        formData.append("userId", userId);

        Axios.post(ENDPOINTS.user.getUser, formData)
            .then( (r) => {
                let user = null;

                let response = API.parse(r);
                switch (response.success) {
                    case true:
                        user = response.data.user;
                        break;

                    case false:
                        this.showModal(
                            "Error",
                            response.error.desc + "[" + response.error.code + "]"
                        );
                        break;
                }

                this.setState({
                    networkInFlight: false,
                    user : user,
                    userRegistrationIsComplete : user.lastLoginDate ? true : false
                });

            }).catch( (error) => {
                console.log(error);
            })
    }

    prepareUserRequestForm() {
        let formData = new FormData();
        let user = this.state.user;
        if (user == null) { return null }

        formData.append("id", user.id);
        formData.append("userRoleId", user.userRoleId);
        formData.append("emailAddress", user.emailAddress);
        formData.append("givenName", user.givenName);
        if (user.middleName !== undefined) {
            formData.append("middleName", user.middleName);
        }
        formData.append("familyName", user.familyName);
        formData.append("dateOfBirth", user.dateOfBirth);
        formData.append("clinicalRoleId", user.clinicalRoleId);
        formData.append("gmcNumber", user.gmcNumber);
        formData.append("placeOfWork", user.placeOfWork);
        formData.append("expectedEndDate", user.expectedEndDate);
        if (user.phoneNumber !== undefined) {
            formData.append("phoneNumber", user.phoneNumber);
        }
        formData.append("allowAccess", 1);

        return formData;
    }

    saveChangesToUser() {
        let formData = this.prepareUserRequestForm()

        Axios.post(ENDPOINTS.user.submitUser, formData)
            .then( (r) => {
                let userHasChanges = this.state.userHasChanges;
                let forgottenPasswordUrl = null;

                let response = API.parse(r);
                switch (response.success) {
                    case true:
                        console.log("User updated");
                        userHasChanges = false
                        break;

                    case false:
                        this.showModal(
                            "Error",
                            response.error.desc + "[" + response.error.code + "]"
                        );
                        break;
                }

                this.setState({
                    userHasChanges: userHasChanges,
                    forgottenPasswordUrl
                });

            }).catch( (error) => {
                console.log(error);
            })
    }

    sendPasswordResetNetworkRequest() {
        let formData = new FormData();
        let userId = this.state.user.id;
        formData.append("userId", userId);

        Axios.post(ENDPOINTS.user.sendPasswordReset, formData)
            .then( (r) => {
                let response = API.parse(r);
                switch (response.success) {
                    case true:
                        console.log("Password reset sent");
                        this.showModal(
                            "Password Reset Sent",
                            "A password reset email has been sent successfully to this user."
                        );
                        break;

                    case false:
                        this.showModal(
                            "Error",
                            response.error.desc + "[" + response.error.code + "]"
                        );
                        console.log(response.error);
                        break;
                }

            }).catch( (error) => {
                console.log(error);
            })
    }

    sendRegistrationReminder() {
        let formData = new FormData();
        let userId = this.state.user.id;
        formData.append("userId", userId);

        Axios.post(ENDPOINTS.user.sendRegistrationReminder, formData)
            .then( (r) => {
                let response = API.parse(r);
                switch (response.success) {
                    case true:
                        if (response.data.forgottenPasswordUrl !== undefined) {
                            this.setState({
                                forgottenPasswordUrl : response.data.forgottenPasswordUrl
                            });
                        }

                        console.log("Registration reset sent");
                        this.showModal(
                            "Registration Reminder Sent",
                            "A registration reminder email has been sent successfully to this user."
                        );
                        break;

                    case false:
                        this.showModal(
                            "Error",
                            response.error.desc + "[" + response.error.code + "]"
                        );
                        console.log(response.error);
                        break;
                }

            }).catch( (error) => {
                console.log(error);
            })
    }

    deleteUser() {
        let formData = new FormData();
        let userId = this.state.user.id;
        formData.append("userId", userId);

        Axios.post(ENDPOINTS.user.deleteUser, formData)
            .then( (r) => {
                let response = API.parse(r);
                switch (response.success) {
                    case true:
                        console.log("User deleted, id: " + userId);
                        this.moveToUserList();
                        break;

                    case false:
                        this.showModal(
                            "Error",
                            response.error.desc + "[" + response.error.code + "]"
                        );
                        console.log(response.error);
                        break;
                }

            }).catch( (error) => {
                console.log(error);
            })
    }


    // MARK: Render

    render() { 
        return (
            <div className={"user-accounts"}>
                {this.renderPasswordResetSection()}
                {this.renderUserVerificationSection()}
                {this.renderHeader()}
                {this.renderUserDetails()}
                {this.renderLinkedCaseRecords()}
                {this.renderAlertModal()}
            </div>
        )
    }

    renderPasswordResetSection = () => {
        if (this.state.forgottenPasswordUrl != null) {
            return (
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <h3>New User Created</h3>
                                <p>The newly created User will need to set their password for the first time before they can get started. Please use the email template below to assist in informing the User of the next steps.</p>

                                <div className={"card"}>
                                    <div className={"card-body"}>
                                        <h1>Welcome to QiNotify</h1>
                                        <p>Congratulations! A new QiNotify User Account has been created for you.</p>
                                        <p>To complete the registration process you must first set your password, please use the link below to set your password:</p>
                                        <p className={"text-center mt-1"}>
                                            <strong><a href={this.state.forgottenPasswordUrl}>{this.state.forgottenPasswordUrl}</a></strong>
                                        </p>
                                        <p>If you believe you have received this email in error. Please ignore this email and take no further action.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return [];
    }

    // MARK: Render Helper Methods

    renderUserVerificationSection() {
        if (!this.state.userRegistrationIsComplete) {
            return (
                <UserVerificationComponent 
                    onSendReminder={() => this.sendRegistrationReminder()}
                    onDeleteUserAndCancelRegistration={() => this.deleteUserTapped()}
                />
            )
        }
    }

    renderHeader() {
        return (
            <div className={"user-accounts"}> 
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"actions"}>
                            <div className={"spacer"} />
                            <div className={"action"}>
                                {this.renderSaveChangesButton()}
                            </div>
                            <div className={"fixed-space"} />
                            {this.renderAccountActionButtons()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderAccountActionButtons() {
        if (this.state.userRegistrationIsComplete) {
            return (
                <React.Fragment>
                    <div className={"action"}>
                        <span className={"btn btn-warning"} onClick={() => this.sendPasswordResetTapped()}>Send Forgotten Password Reset</span> 
                    </div>
                    <div className={"fixed-space"} />
                    <div className={"action"}>
                        <span className={"btn btn-danger"} onClick={() => this.deleteUserTapped()}>Delete User</span>
                    </div>
                </React.Fragment>
            )
        }
    }

    renderSaveChangesButton() {
        if (this.state.userHasChanges && this.state.userIsReadyForSubmission) {
            return <span className={"btn btn-primary"} onClick={() => this.saveChangesTapped()}>Save Changes</span>;
        } else {
            return <span className={"btn btn-primary disabled"}>Save Changes</span>;
        }
    }

    renderUserDetails() {
        let user = this.state.user
        if (user == null) { return }
        return (
            <UserDetailsComponent 
                user={user} 
                userStateDidChange={(user) => this.userStateDidChange(user)}
                renderUserType={true}
            />
        )
    }

    renderLinkedCaseRecords() {
        let user = this.state.user
        if (user == null) { return }
        return (
            <LinkedRecordsComponent
                {...this.props}
                user={user} />
        )
    }

    renderAlertModal() {
        return (
            <AlertModal
                open={this.state.modalOpen}
                title={this.state.modalTitle}
                content={this.state.modalContent}
                buttons={this.state.modalButtons}
                dismissHandler={this.hideModal} 
            />
        )
    }
}
 
export default ViewUserComponent;