import React from "react";
import { useHistory } from "react-router-dom";
import {API} from "../network/API";

class BaseComponent extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {
        //this.history = useHistory();
    }

    /**
     * Initialises the state for this component
     * @param state     State Object
     */
    initState(state) {
        this.state = state;
        this.setState(state);
    }

    /**
     * Navigates to another screen
     * @param location      Relative URL to travel to. e.g. /login
     */
    navigate(location) {
        //this.history.push(location);
    }

    /**
     * Handler for input items to ensure the value of the input is put into the state
     * @param event     Event from the onChange property
     */
    handleChange(event) {
        const target = event.target;
        const name = target.name;

        let value = target.value;
        if (target.type === "checkbox") {
            value = target.checked;
        }

        this.setState({
            [name] : value
        });
    }

    getOrDefault = (object, keyName, defaultValue) => {
        if (object.hasOwnProperty(keyName)) {
            let out = object[keyName];
            if (out !== null && out !== undefined) {
                return out;
            }
        }
        return defaultValue;
    }

    getUser = () => {
        let user = sessionStorage.getItem("user");
        if (user != null) {
            user = JSON.parse(user);

            user.visibility = API.visibility.LIMITED;
            if (user.hasOwnProperty("fullView")) {
                if (parseInt(user.fullView) === 1) {
                    user.visibility = API.visibility.FULL;
                }
            } else if (user.hasOwnProperty("userRoleId")) {
                user.visibility = API.getVisibilityForUserRole(user.userRoleId);
            }
        }
        return user;
    }

    /**
     * Validate an Object and create Form Data from it
     *
     * @param inObj         Object to validate
     * @param ruleArray     Rules to validate against
     * @returns {{success: boolean}}        Validation Result Bundle
     */
    validateCreateFormData(inObj, ruleArray) {
        var outBundle = {
            success : true
        };

        var formData = new FormData();

        for (var i = 0; i < ruleArray.length; i++) {
            let rules = ruleArray[i];

            let keyName = rules.key;
            let type = rules.type;
            let label = rules.label;
            let postName = rules.postName;
            let optional = rules.optional;
            let defaultValue = rules.default;

            if (keyName === undefined) {
                console.log("Validation rule was passed with no key. Skipping.");
                continue;
            }

            if (type === undefined) {
                type = "string";
            }

            if (optional === undefined) {
                optional = false;
            }

            if (inObj.hasOwnProperty(keyName)) {
                var valid = true;
                var invalidReason = "";

                var value = inObj[keyName];
                if (value === undefined && defaultValue !== undefined) {
                    value = defaultValue;
                }

                if (type !== undefined) {
                    if (type === "int" || type === "integer" || type === "float" || type === "double") {
                        if (type === "int" || type === "integer") {
                            value = parseInt(value);
                        } else if (type === "float" || type === "double") {
                            value = parseFloat(value);
                        }

                        if (isNaN(value)) {
                            valid = false;
                            invalidReason = "Please provide a valid number for " + label;
                        }

                        if (valid && rules.hasOwnProperty("greaterZero")) {
                            if (rules.greaterZero) {
                                if (value <= 0) {
                                    valid = false;
                                    invalidReason = "Please provide a number greater than zero for " + label;
                                }
                            }
                        }

                        if (valid && rules.hasOwnProperty("greaterEqualsZero")) {
                            if (rules.greaterEqualsZero) {
                                if (value < 0) {
                                    valid = false;
                                    invalidReason = "Please provide a number greater or equal to zero for " + label;
                                }
                            }
                        }
                    } else if (type === "string") {
                        var notEmpty = true;
                        if (rules.hasOwnProperty("notEmpty")) {
                            notEmpty = rules.notEmpty
                        }

                        if (notEmpty) {
                            if (value === "" || value == null) {
                                valid = false;
                                invalidReason = "Please provide a " + label;
                            }
                        }
                    }
                }

                if (valid) {
                    if (postName !== undefined) {
                        formData.append(postName, value);
                    } else {
                        formData.append(keyName, value);
                    }
                } else if (!optional) {
                    outBundle.success = false;
                    outBundle.error = invalidReason;
                    break;
                }
            } else if (!optional) {
                if (defaultValue !== undefined && defaultValue != null && postName !== undefined) {
                    formData.append(postName, defaultValue);
                } else {
                    outBundle.success = false;
                    outBundle.error = "Please provide a " + label;
                    break;
                }
            }
        }

        if (outBundle.success) {
            outBundle.formData = formData;
        }

        return outBundle;
    }


    getUriProperty = (keyName, defaultValue, props) => {
        if (props === undefined) {
            props = this.props;
        }

        if (props !== undefined) {
            if (props.match !== undefined) {
                if (props.match.params !== undefined) {
                    if (props.match.params[keyName] !== undefined) {
                        return props.match.params[keyName];
                    }
                }
            }
        }

        return defaultValue;
    }

    getHashProperties = () => {
        let uri = window.location.href;

        let out = {};

        let parts = uri.split("#");
        if (parts.length > 1) {
            let hashString = parts[1];
            let hashParts = hashString.split("&");

            for (let i = 0; i < hashParts.length; i++) {
                let paramSplit = hashParts[i].split("=");
                out[paramSplit[0]] = paramSplit[1];
            }
        }

        return out;
    }

    getHashProperty = (keyName, defaultValue) => {
        let properties = this.getHashProperties();
        if (properties.hasOwnProperty(keyName)) {
            return properties[keyName];
        }
        return defaultValue;
    }

    createHashParameters = (inObj) => {
        let out = "";
        for (const [key, value] of Object.entries(inObj)) {
            if (out === "") {
                out = "#";
            } else {
                out += "&";
            }
            out += escape(key) + "=" + escape(value);
        }
        return out;
    }

}

export default BaseComponent;