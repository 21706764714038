import React from 'react';
import BaseComponent from "../BaseComponent";

import "./ReportsLandingComponent.css";
import ReportsStatisticsComponent from "./ReportsStatisticsComponent";
import ReportsMortalityComponent from "./ReportsMortalityComponent";
import ReportsBTPStatisticsComponent from "./ReportsBTPStatisticsComponent";
import OpenFeedbackReportComponent from "./OpenFeedbackReportComponent";

import { v4 as uuidv4 } from "uuid";

export default class ReportsLandingComponent extends BaseComponent {

    TAB_STATISTICS_REPORTS = 1;
    TAB_BTP_REPORTS = 2;
    TAB_MORTALITY_REPORT = 3;
    TAB_OPEN_FEEDBACK_REPORTS = 4;

    navTabs = [
        {
            id : this.TAB_STATISTICS_REPORTS,
            label : "Statistics Reports",
            uri : "statistics"
        },
        {
            id : this.TAB_BTP_REPORTS,
            label : "BPT Reports",
            uri : "btp"
        },
        {
            id : this.TAB_MORTALITY_REPORT,
            label : "Mortality Report",
            uri : "mortality"
        },
        {
            id : this.TAB_OPEN_FEEDBACK_REPORTS,
            label : "Open Feedback",
            uri : "open-feedback"
        }
    ]


    constructor(props, context) {
        super(props, context);

        let section = this.getUriProperty("section", this.getUriValueForSelection(this.TAB_STATISTICS_REPORTS));
        let selectedTab = this.getTabIdForSelection(section);

        let reportStates = {};

        this.initState({
            selectedTab,
            reportStates
        });
    }
    
    componentDidMount() {
        this.props.title("Summary Reports");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let previousSelection = this.getUriProperty("section", null, prevProps);
        let currentSelection = this.getUriProperty("section", null);

        if (previousSelection !== currentSelection) {
            let selectedTab = this.getTabIdForSelection(currentSelection);
            this.setState({
                selectedTab
            });
        }
    }

    changeTab = (selectedTab) => {
        let uriSuffix = this.getUriValueForSelection(selectedTab);
        this.props.history.push("/reports/" + uriSuffix);
    }

    getUriValueForSelection = (tabId) => {
        for (let i = 0; i < this.navTabs.length; i++) {
            if (this.navTabs[i].id === tabId) {
                return this.navTabs[i].uri;
            }
        }
        return null;
    }

    getTabIdForSelection = (uri) => {
        for (let i = 0; i < this.navTabs.length; i++) {
            if (this.navTabs[i].uri === uri) {
                return this.navTabs[i].id;
            }
        }
        return null;
    }

    reportStateDidChange = (tabId, state) => {
        let reportStates = this.state.reportStates;
        reportStates[tabId] = state;

        this.setState({
            reportStates
        })
    }

    getReportState = (tabId) => {
        if (this.state.reportStates.hasOwnProperty(tabId)) {
            return this.state.reportStates[tabId];
        }
        return undefined;
    }

    render() {
        let contentComponent = [];
        switch (this.state.selectedTab) {
            case this.TAB_STATISTICS_REPORTS:
                contentComponent  = (
                    <ReportsStatisticsComponent
                        savedState={this.getReportState(this.TAB_STATISTICS_REPORTS)}
                        stateCallback={(state) => this.reportStateDidChange(this.TAB_STATISTICS_REPORTS, state)}
                        {...this.props} />
                )
                break;
            case this.TAB_BTP_REPORTS:
                contentComponent = (
                    <ReportsBTPStatisticsComponent
                        savedState={this.getReportState(this.TAB_BTP_REPORTS)}
                        stateCallback={(state) => this.reportStateDidChange(this.TAB_BTP_REPORTS, state)}
                        {...this.props} />
                )
                break;
            case this.TAB_MORTALITY_REPORT:
                contentComponent = (
                    <ReportsMortalityComponent
                        savedState={this.getReportState(this.TAB_MORTALITY_REPORT)}
                        stateCallback={(state) => this.reportStateDidChange(this.TAB_MORTALITY_REPORT, state)}
                        {...this.props} />
                )
                break;
            case this.TAB_OPEN_FEEDBACK_REPORTS:
                contentComponent = (
                    <OpenFeedbackReportComponent
                        savedState={this.getReportState(this.TAB_OPEN_FEEDBACK_REPORTS)}
                        stateCallback={(state) => this.reportStateDidChange(this.TAB_OPEN_FEEDBACK_REPORTS, state)}
                        {...this.props} />
                )
                break;
        }

        return (
            <div className={"reports-landing-component"}>
                <div className={"row"}>
                    <div className={"col-12 header-tab-container"}>
                        <ul className="nav nav-tabs">
                            {
                                this.navTabs.map((item) => (
                                    <li className="nav-item">
                                        <span className={"nav-link" + ((item.id === this.state.selectedTab) ? " active" : "")} onClick={() => this.changeTab(item.id)}>{item.label}</span>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        {contentComponent}
                    </div>
                </div>
            </div>
        )
    }
}