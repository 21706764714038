import React from 'react';
import BaseComponent from "../BaseComponent";

import "./UserDetailComponent.css";
import PasswordChangeModalComponent from "./PasswordChangeModalComponent";
import AlertModal from "../common/AlertModal";

export default class UserDetailComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let user = this.getUser();

        this.initState({
            user,
            passwordModalOpen : false,
            modalOpen : false
        });
    }

    componentDidMount() {
        this.props.title("User Details");
    }

    showPasswordModal = () => {
        this.setState({
            passwordModalOpen : true
        });
    }

    passwordModalDidDismiss = (result) => {
        let alertMessage = undefined;
        if (result !== undefined) {
            if (result) {
                alertMessage = "Successfully changed password"
            }
        }

        this.setState({
            passwordModalOpen : false,
            alertMessage
        });
    }

    dismissAlertModal = () => {
        this.setState({
            alertMessage : undefined
        });
    }

    promptToSignOut = () => {
        this.showModal("Sign Out", "Please confirm you wish to sign out", [
            {
                label : "Cancel",
                style : "btn-secondary",
                click : () => {
                    this.hideModal();
                }
            },
            {
                label : "Sign Out",
                style : "btn-danger",
                click : () => {
                    this.props.onLogout();
                    this.hideModal();
                }
            }
        ])
    }

    showModal = (title, message, buttons) => {
        if (buttons === undefined) {
            buttons = [
                {
                    label : "OK",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]
        }

        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalContent : message,
            modalButtons : buttons
        });
    }

    hideModal = () => {
        this.setState({
            modalOpen : false
        });
    }

    render() {
        let alertElem = [];
        if (this.state.alertMessage !== undefined) {
            alertElem = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className="alert alert-success clickable" role="alert" onClick={this.dismissAlertModal}>
                            {this.state.alertMessage}
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className={"user-detail-component"}>
                {alertElem}

                <div className={"row"}>
                    <div className={"col-8"}>
                        <h2>Personal Details</h2>
                    </div>

                    <div className={"col-4 text-right"}>
                        <button className={"btn btn-danger"} onClick={this.promptToSignOut}>Sign Out</button>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"card user-details"}>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6 pb-2"}>
                                        <label>First Name</label>
                                        <input type={"text"} className={"form-control"} value={this.state.user.givenName} readOnly />
                                    </div>

                                    <div className={"col-12 col-md-6 pb-2"}>
                                        <label>Last Name</label>
                                        <input type={"text"} className={"form-control"} value={this.state.user.familyName} readOnly/>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12 col-md-6 pb-2"}>
                                        <label>Role</label>
                                        <input type={"text"} className={"form-control"} value={this.state.user.role} readOnly/>
                                    </div>

                                    <div className={"col-12 col-md-6 pb-2"}>
                                        <label>Email</label>
                                        <input type={"text"} className={"form-control"} value={this.state.user.emailAddress} readOnly/>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12 pb-2"}>
                                        <button className={"btn btn-success"} onClick={this.showPasswordModal}>Change Password</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <PasswordChangeModalComponent
                    open={this.state.passwordModalOpen}
                    dismissCallback={this.passwordModalDidDismiss} />

                <AlertModal
                    open={this.state.modalOpen}
                    title={this.state.modalTitle}
                    content={this.state.modalContent}
                    buttons={this.state.modalButtons}
                    dismissHandler={this.hideModal} />
            </div>
        )
    }
}